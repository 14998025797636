nav {
  height: 100%;
}

.side-nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffc000 !important;
}

.side-nav-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 30%;
}

.nav-bar {
  position: fixed;
  width: 100%;
  top: 0px;
  height: 4rem;
  z-index: 2;
}

.user-icon {
  margin-right: 3px;
}
